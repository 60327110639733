import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import shouldShowPostUpdateDate from '@wix/communities-blog-universal/dist/src/utils/should-show-post-update-date';

import TimeAgo from '../time-ago';
import styles from './post.scss';

const PostUpdatedDate = ({ post }) =>
  shouldShowPostUpdateDate(post) ? (
    <p className={classNames(styles.updateDate, styles.mobileContainer)}>
      <Trans i18nKey="post.update-date-new" components={[<TimeAgo time={post.lastPublishedDate} />]} />
    </p>
  ) : null;

PostUpdatedDate.propTypes = {
  post: PropTypes.object.isRequired,
};

export default PostUpdatedDate;
